import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'
import Page from '../components/Page'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(200%)
  }
  100% {
    transform: translateY(0)
  }
`

const grow = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`

const leftMarquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`

const AboutPageLayout = styled.div`
  width: 100%;

  .section-one {
    display: grid;
    align-items: end;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
    background-color: ${(props) => props.theme.backgroundBlue};
    height: 85vh;
    min-height: 730px;
    padding: 6rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      height: 50vh;
      padding: 6rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      height: 60vh;
      min-height: 600px;
      padding: 6rem 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
      height: calc(100vh - 120px);
      min-height: calc(100vh - 120px);
      padding-bottom: 5rem;
    }

    .text-left {
      padding-bottom: 4rem;

      @media (max-width: ${breakpoints.screenL}) {
        padding-bottom: 3rem;
      }

      h1 span {
        color: ${(props) => props.theme.backgroundYellow};
        font-family: ${(props) => props.theme.fontSecondary};
      }

      p {
        position: relative;
        max-width: 10rem;
        padding-bottom: 0.5rem;
        margin-bottom: 2rem;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 3px;
          background-color: ${(props) => props.theme.backgroundYellow};
          transform-origin: left;
          transform: scale(0);
          animation: ${grow} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 0.2s;
        }
      }
    }

    .text-right {
      align-self: end;
      margin-left: auto;
      text-align: right;
      opacity: 0;
      animation: ${fadeIn} 0.8s ease forwards 0.4s;

      p {
        max-width: 13rem;
      }
    }

    .headline {
      overflow: hidden;
    }

    h1 {
      transform: translateY(200%);
      animation: ${slideUp} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 0.2s;
    }

    .title {
      opacity: 0;
      animation: ${fadeIn} 0.4s ease forwards 0.2s;
    }
  }

  .section-two {
    padding-top: 10rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 8rem 0;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 4rem 0;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 4rem 1.5rem;
    }

    .image {
      padding-left: 9rem;

      @media (max-width: ${breakpoints.screenSM}) {
        padding-left: 0;
        height: 400px;
      }

      img {
        width: 100%;

        @media (max-width: ${breakpoints.screenSM}) {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content {
      padding: 8rem 9rem;

      @media (max-width: ${breakpoints.screenLG}) {
        padding: 6rem 3rem;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        padding: 4rem 1.5rem;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        padding: 2rem 0;
      }
    }

    h1 {
      max-width: 50%;
      padding-bottom: 2rem;

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 100%;
      }

      span {
        color: ${(props) => props.theme.backgroundYellow};
        font-family: ${(props) => props.theme.fontSecondary};
      }
    }

    .title {
      position: relative;
      max-width: 8rem;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.backgroundBlue};
      }
    }

    .text {
      max-width: 70%;

      @media (max-width: ${breakpoints.screenL}) {
        max-width: 85%;
      }

      @media (max-width: ${breakpoints.screenLG}) {
        max-width: 90%;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 100%;
      }
    }

    br {
      display: none;

      @media (max-width: ${breakpoints.screenSM}) {
        display: block;
      }
    }
  }

  .section-three {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.backgroundBlue};
    padding: 0 9rem 2rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 1rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 0;
    }

    .content {
      max-width: 45%;

      @media (max-width: ${breakpoints.screenL}) {
        max-width: 50%;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 100%;
        padding: 0 1.5rem 2rem;
      }
    }

    .image {
      max-width: 40%;
      transform: translateY(-7%);

      @media (max-width: ${breakpoints.screenLG}) {
        max-width: 44%;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 70%;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        max-width: 100%;
        padding-right: 1.5rem;
      }
    }

    h1 {
      padding-bottom: 2rem;
    }

    .title {
      position: relative;
      max-width: 8rem;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.backgroundYellow};
      }
    }
  }

  .section-four {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.backgroundYellow};
    padding: 0 9rem 2rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 1rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    .content {
      max-width: 45%;
      text-align: right;

      @media (max-width: ${breakpoints.screenL}) {
        max-width: 50%;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 100%;
        padding: 0 1.5rem 2rem;
        text-align: left;
      }
    }

    .image {
      width: 100%;
      max-width: 40%;
      transform: translateY(13%);

      @media (max-width: ${breakpoints.screenLG}) {
        max-width: 44%;
        transform: translateY(8%);
      }

      @media (max-width: ${breakpoints.screenMD}) {
        max-width: 70%;
        transform: translateY(0);
        margin-left: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        max-width: 100%;
        padding-left: 1.5rem;
        margin-top: 2rem;
      }

      img {
        width: 100%;
      }
    }

    h1 {
      padding-bottom: 2rem;
    }

    .title {
      position: relative;
      max-width: 8rem;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
      margin-left: auto;

      @media (max-width: ${breakpoints.screenMD}) {
        margin-left: 0;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.backgroundBlue};
      }
    }
  }

  .section-five {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 14rem 9rem 6rem;

    @media (max-width: ${breakpoints.screenLG}) {
      grid-template-columns: repeat(3, 1fr);
      padding: 6rem 3rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      gap: 2rem;
      padding: 4rem 1.5rem 2rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
    }
  }

  .section-six {
    padding: 2rem 0 10rem;
    overflow-x: hidden;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 2rem 0 6rem;
    }

    .content {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      min-width: 100%;

      &:hover .marquee {
        animation-play-state: paused;
      }
    }

    .marquee {
      display: flex;
      padding-right: 2rem;
      animation: ${leftMarquee} 20s linear infinite;

      @media (max-width: ${breakpoints.screenMD}) {
        padding-right: 1rem;
      }
    }
  }

  .section-seven {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 4rem 9rem 8rem;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 4rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 2rem 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
    }

    .quote {
      position: sticky;
      top: 0.5rem;
      height: fit-content;

      @media (max-width: ${breakpoints.screenSM}) {
        position: relative;
      }
    }

    h1 {
      padding-bottom: 2rem;
      max-width: 75%;

      @media (max-width: ${breakpoints.screenLG}) {
        max-width: 100%;
      }

      span {
        color: ${(props) => props.theme.backgroundYellow};
        font-family: ${(props) => props.theme.fontSecondary};
      }
    }

    .title {
      position: relative;
      max-width: 14rem;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.textPrimary};
      }
    }

    .content {
      padding-left: 4rem;

      @media (max-width: ${breakpoints.screenSM}) {
        padding-left: 0;
        padding-top: 2rem;
      }
    }

    .list {
      padding-bottom: 4rem;

      :last-child {
        padding-bottom: 2rem;
      }

      &.active h3 {
        color: ${(props) => props.theme.backgroundBlue};
      }
    }

    h3 {
      padding-bottom: 2rem;
      will-change: color;
      transition: color 0.4s ease;
    }
  }
`

export default AboutPageLayout
