import React, { useEffect } from 'react'

import imgHero from '../assets/images/home-hero.png'
import img1 from '../assets/images/img-about-1.gif'
import img2 from '../assets/images/img-about-2.gif'
import img3 from '../assets/images/img-about-3.gif'

import SEO from '../components/SEO'
import Header from '../components/Header'

import { theme, colors } from '../constants/theme'
import { team, values } from '../constants/content'

import { highlightElementInView } from '../helpers/ui'

import AboutPageLayout from '../styles/AboutPageLayout'
import { TeamSquare } from '../styles/PageLayout'
import {
  HeadlineL,
  HeadlineM,
  SubtitleL,
  SubtitleM,
  ParagraphM,
  ParagraphS,
} from '../styles/Typography'

const AboutPage = () => {
  useEffect(() => {
    window.addEventListener('scroll', highlightElementInView)

    return () => {
      window.removeEventListener('scroll', highlightElementInView)
    }
  }, [])

  return (
    <>
      <SEO title="Mint and Square | O nas" image={imgHero} />
      <Header
        logoBackground={colors.yellow}
        logoBorder={colors.yellow}
        linkText={colors.white}
        menuLines={colors.yellow}
      />
      <AboutPageLayout>
        <div className="section-one">
          <div className="text-left">
            <div className="title">
              <ParagraphM color={colors.white}>O nas</ParagraphM>
            </div>
            <div className="headline">
              <HeadlineM color={colors.white}>
                Kreativna agencija, kjer se srečujeta <span>kreativnost </span>
                in <span>odgovornost</span>
              </HeadlineM>
            </div>
          </div>
          <div className="text-right">
            <ParagraphM color={colors.white} weight="300">
              Mi smo jing & jang, mi smo Mint & Square.
            </ParagraphM>
          </div>
        </div>

        <div className="section-two">
          <div className="image">
            <img src={img1} alt="Mint and Square" />
          </div>
          <div className="content">
            <ParagraphM color={colors.black} className="title">
              O nas
            </ParagraphM>
            <HeadlineM color={colors.black}>
              Velike <span>zgodbe </span> in majhni <span>začetki</span>
            </HeadlineM>
            <ParagraphM color={colors.black} weight="300" className="text">
              Vsaka zgodba se enkrat začne in najboljše se običajno začnejo čisto potiho in nevede.
              Kot recimo zgodba kreativne agencije Mint & Square, ki se je začela leta 2017 pod
              drugim imenom in z drugim namenom. <br />
              <br />
              Manevrirali smo med raznoraznimi projekti, preskakovali ovire, raziskovali,
              odkrivali.. in počasi ugotavljali v čemu smo najboljši. Smo kreativni in odgovorni,
              igrivi in resni, online in offline. Mi smo Mint & Square.
            </ParagraphM>
          </div>
        </div>

        <div className="section-three">
          <div className="content">
            <ParagraphM color={colors.white} className="title">
              Misija
            </ParagraphM>
            <HeadlineM color={colors.white}>Naj živijo nore ideje.</HeadlineM>
            <ParagraphM color={colors.white} weight="300" className="text">
              Tanka je meja med norim in neumnim. In vsaka nora ideja se bo nekomu zdela neumna. Mi
              verjamemo v nore ideje. V tiste, ki vam ne pustijo spati ponoči. Zato smo na misiji,
              da nore ideje pripeljemo do končnih uporabnikov.
            </ParagraphM>
          </div>
          <div className="image">
            <img src={img2} alt="Mint and Square" />
          </div>
        </div>

        <div className="section-four">
          <div className="image">
            <img src={img3} alt="Mint and Square" />
          </div>
          <div className="content">
            <ParagraphM color={colors.white} className="title">
              Vizija
            </ParagraphM>
            <HeadlineM color={colors.white}>Stati za najboljšimi.</HeadlineM>
            <ParagraphM color={colors.white} weight="300" className="text">
              Stati za tistimi, ki se ne sprašujejo samo zakaj, ampak tudi zakaj pa ne. Naša vizija
              je, v naslednjih petih letih postali agencija, ki bo ‘partner-in-crime’ nekaterim
              najbolj prepoznavnim produktom na slovenskem trgu.
            </ParagraphM>
          </div>
        </div>

        <div className="section-five">
          {team.map((person, index) => (
            <TeamSquare key={index} background={person.color}>
              <img src={person.img} alt={person.name} />
              <div className="box">
                <div className="text">
                  <SubtitleM color={colors.white}>{person.name}</SubtitleM>
                  <ParagraphS color={colors.white} weight="300">
                    {person.title}
                  </ParagraphS>
                </div>
                <div className="overlay"></div>
              </div>
            </TeamSquare>
          ))}
        </div>

        <div className="section-six">
          <div className="content">
            <HeadlineL font={theme.fontSecondary} color={colors.blue} className="marquee">
              Mi se ne sprašujemo le zakaj, ampak tudi zakaj pa ne.
            </HeadlineL>
            <HeadlineL font={theme.fontSecondary} color={colors.blue} className="marquee">
              Mi se ne sprašujemo le zakaj, ampak tudi zakaj pa ne.
            </HeadlineL>
            <HeadlineL font={theme.fontSecondary} color={colors.blue} className="marquee">
              Mi se ne sprašujemo le zakaj, ampak tudi zakaj pa ne.
            </HeadlineL>
          </div>
        </div>

        <div className="section-seven">
          <div className="quote">
            <ParagraphM color={colors.black} className="title">
              Kaj nas navdihuje
            </ParagraphM>
            <HeadlineM color={colors.black}>
              Naša <span>kultura </span> in <span>vrednote</span>.
            </HeadlineM>
          </div>
          <div className="content">
            {values.map((value) => (
              <div key={value.id} className="list">
                <SubtitleL font={theme.fontSecondary}>{value.title}</SubtitleL>
                <ParagraphM weight="300">{value.text}</ParagraphM>
              </div>
            ))}
          </div>
        </div>
      </AboutPageLayout>
    </>
  )
}

export default AboutPage
