import imgProject1 from '../assets/images/img-project-1.png'
import imgProject2 from '../assets/images/img-project-2.png'
import imgProject3 from '../assets/images/img-project-3.png'
import imgProject4 from '../assets/images/img-project-4.png'
import imgProject5 from '../assets/images/img-project-5.png'
import imgProject6 from '../assets/images/img-project-6.jpg'
import imgProject7 from '../assets/images/img-project-7.png'
import imgProject8 from '../assets/images/img-project-8.png'
import imgProject9 from '../assets/images/img-project-9.png'
import imgProject10 from '../assets/images/img-project-10.png'
import imgProject11 from '../assets/images/img-project-11.png'
import imgProject12 from '../assets/images/img-project-12.png'
import imgProject13 from '../assets/images/img-project-13.png'

import imgTeam1 from '../assets/images/img-team-1.png'
import imgTeam2 from '../assets/images/img-team-2.png'
import imgTeam3 from '../assets/images/img-team-3.png'
import imgTeam4 from '../assets/images/img-team-4.png'
import imgTeam5 from '../assets/images/img-team-5.png'
import imgTeam6 from '../assets/images/img-team-6.png'

import { colors } from './theme'

/* Global content */

export const tags = [
  'Vodenje družbenih omrežij',
  'Fotografija',
  'Video produkcija',
  'Spletne strani',
  'Spletne trgovine',
  'Organizacija dogodkov',
  'Google oglasi',
  'Oglaševanje na družbenih omrežjih',
  'Grafično oblikovanje',
  'UI',
  'UX',
  'Znamčenje',
  'Tekstopisje',
]

export const team = [
  {
    img: imgTeam1,
    name: 'Gašper Sovdat',
    title: 'CEO in partner',
    color: colors.blue,
  },
  {
    img: imgTeam2,
    name: 'Romina Gerbec',
    title: 'kreativna vodja in partner',
    color: colors.yellow,
  },
  {
    img: imgTeam3,
    name: 'Saša Vujasinović',
    title: 'dogodki',
    color: colors.purple,
  },
  {
    img: imgTeam4,
    name: 'Blaž Peruš',
    title: 'spletne strani',
    color: colors.purple,
  },
  {
    img: imgTeam5,
    name: 'Lejla Harbaš',
    title: 'digitalno oglaševanje',
    color: colors.blue,
  },
  {
    img: imgTeam6,
    name: 'Lucija Karnelutti',
    title: 'ustvarjalka vsebin',
    color: colors.yellow,
  },
]

export const values = [
  {
    id: 1,
    title: 'Kreativnost',
    text:
      'Kam pa bi prišli brez kreativnosti? Brez ustvarjanja in prepoznavanja dobrih idej, alternativ in vseh možnosti, ki nam lahko pomagajo pri reševanju problemov in nas, nenazadnje, še zabavajo.',
  },
  {
    id: 2,
    title: 'Fleksibilnost',
    text:
      'Manj je več in večje ni nujno boljše. Smo ponosno majhna butična agencija, ki se hitro prilagaja potrebam trga, naročnikom in tudi našim sodelavcem.',
  },
  {
    id: 3,
    title: 'Odgovornost',
    text:
      'Rdeča nit in okvir okrog Minta. Držimo se dogovorjenih rokov. Izbiramo sodelavce, ki jih odgovornost motivira. Radi sodelujemo na projektih, ki so odgovorni do okolja.',
  },
  {
    id: 4,
    title: 'Radovednost',
    text:
      'Konstantno raziskovanje zakaj-ev in kaj pa če-jev v vsakodnevnem življenju pripelje do izjemnih ugotovitev in priložnosti v poslu. Negujemo in spodbujamo radovednost na vseh področjih. ',
  },
  {
    id: 5,
    title: 'Izzivi in rast',
    text:
      'Kaj je bilo prej izziv ali rast? Naša filozofija ves čas skrbi za preplet obojega. Sprejemamo izzive in z vsakim malo zrastemo. In ko malo zrastemo potrebujemo nov izziv.',
  },
]

/* Projects' content */

export const featuredProjects = [
  {
    img: imgProject1,
    title: 'Nord Hard Seltzer',
    tags: [
      'Grafično oblikovanje',
      'Spletne strani',
      'Znamčenje',
      'Oglaševanje na družbenih omrežjih',
    ],
    link: '/project/nord-hard-seltzer',
  },
  {
    img: imgProject2,
    title: 'Butik Festival',
    tags: [
      'Organizacija dogodkov',
      'Grafično oblikovanje',
      'Spletne strani',
      'Znamčenje',
      'Oglaševanje na družbenih omrežjih',
      'Video produkcija',
    ],
    link: '/project/butik-festival',
  },
  {
    img: imgProject3,
    title: 'Coronini Caffe',
    tags: ['Družbena omrežja', 'E-mail oglaševanje', 'Tekstopisje', 'Grafično oblikovanje'],
    link: '/project/coronini-caffee',
  },
  {
    img: imgProject4,
    title: 'GoodPlace',
    tags: [
      'Oglaševanje na družbenih omrežjih',
      'Google oglasi',
      'Grafično oblikovanje',
      'E-mail oglaševanje',
    ],
    link: '/project/visit-good-place',
  },
  {
    img: imgProject5,
    title: 'Mlada Pobuda',
    tags: [
      'Oglaševanje na družbenih omrežjih',
      'Logotip',
      'Grafično oblikovanje',
      'Video produkcija',
    ],
    link: '/project/mlada-pobuda',
  },
  {
    img: imgProject6,
    title: 'Gengigel',
    tags: ['UI', 'UX'],
    link: '/project/gengigel',
  },
  {
    img: imgProject7,
    title: 'Slovenia Green',
    tags: [
      'UX',
      'UI',
      'Vodenje družbenih omrežij',
      'Digitalno oglaševanje',
      'Grafično oblikovanje',
      'Tekstopisje',
      'Email marketing',
      'Google oglasi',
    ],
    link: '/project/slovenia-green',
  },
  {
    img: imgProject8,
    title: 'To poletje bo Nord!',
    tags: [
      'Video produkcija',
      'Offline oglaševanje',
      'Tekstopisje',
      'Družbena omrežja',
      'Grafično oblikovanje',
    ],
    link: '/project/to-poletje-bo-nord',
  },
]

export const otherProjects = [
  {
    img: imgProject9,
    title: 'Vitasis',
    tag: 'UX / UI',
    link: '/project/vitasis',
  },
  {
    img: imgProject10,
    title: 'Občina Tolmin',
    tag: 'Grafično oblikovanje',
    link: '/project/obcina-tolmin',
  },
  {
    img: imgProject11,
    title: 'IP Posočje',
    tag: 'Grafično oblikovanje',
    link: '/project/ip-posocje',
  },
  {
    img: imgProject12,
    title: 'Atrium Interieri',
    tag: 'Grafično oblikovanje',
    link: '/project/atrium-interieri',
  },
  // {
  //   img: imgProject13,
  //   title: 'Občina Tolmin',
  //   tag: 'Grafično oblikovanje',
  //   link: '/project/tolmin-2030',
  // },
]
